<template>
    <div>
        <loadingComponent @closeDialog="dialog = $event" v-if="dialog" :dialogProps="dialog" :loadingProps="loading"
            :errorProps="error" />
        <v-dialog v-model="dialogAddCompteur" persistent width="1250" min-height="250">
            <v-card>
                <v-toolbar :color="$colors[0]" dark>
                    <v-card-title class="text-h6">
                        Edition contrat GEG
                    </v-card-title>
                </v-toolbar>

                <v-form ref="form" lazy-validation v-if="ajouterUnAutreCompteur == true">
                    <v-card-actions class="d-flex flex-row">
                        <div class="d-flex flex-column">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <p><b>N° Compteur selectionné:</b> <small>{{ numCompteur }}</small></p>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-autocomplete v-model="selectedCompteur"
                                    :items="listCompteurResult.filter(item => !item.typeCompteur?.includes('C5'))"
                                    :loading="isLoading" hide-no-data hide-selected label="Autre compteur"
                                    placeholder="Ajouter un compteur" prepend-icon="mdi-account-search-outline" chips
                                    item-text="numCompteur" item-value="numCompteur" deletable-chips multiple
                                    return-object clearable>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0">
                                            <span>{{ item.numCompteur }}</span>
                                        </v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">
                                            (+{{ selectedCompteur.length - 1 }} {{ selectedCompteur.length - 1 > 1 ?
                                                'éléments' : "élément" }})
                                        </span>
                                    </template>
                                    <template v-slot:item="data">
                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.numCompteur"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="toggle">
                                            <v-list-item-content>
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </div>
                    </v-card-actions>
                </v-form>
                <v-col>

                    <div class="d-flex flex-column ma-4">
                        <div class="d-flex flex-row justify-end">
                            <v-btn color="error" text @click="closeModal">Fermer</v-btn>
                            <v-btn :color="$colors[0]" class="white--text" @click="createContrat">
                                Generer contrat
                                <v-icon dense class="ml-2">mdi-file-upload</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-card>
        </v-dialog>

        <div>
            <v-btn @click="openDialog" icon depressed class="ma-auto text-capitalize">
                <v-icon :color="$colors[0]">mdi-text-box-edit</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
import loadingComponent from "../../components/ui/loading-component.vue"
import { degrees, PDFDocument, rgb, StandardFonts, layoutMultilineText, setFillingRgbColor, setFontAndSize } from 'pdf-lib'
import download from 'downloadjs'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import axios from 'axios'
import dayjs from "dayjs"
export default {
    name: "Geg",
    components: { loadingComponent },
    props: {
        donner: null,
        numCompteur: null,
        listCompteur: [],
        donnerCompteurs: [],
        societe: null,
        donnerEnedis: null,
        optionTarifaire: null,
    },
    data() {
        return {
            perPage: 3,
            currentPage: 1,
            rules: {
                required: value => !!value || 'Ce champ est obligatoire.',
                superiorToday: value => value > dayjs() || `La date doit etre supérieure ou égale au ${dayjs().format('DD/MM/YYYY')}`
            },
            loadingTableHistorique: false,
            fieldhistoriqueDeCotation: [
                { key: "numCompteur", label: "Numéro de compteur" },
                { key: "tarif", label: "Tarif" },
                { key: "profil", label: "Profil" },
                { key: "conso", label: "Consommation (Mwh)" },
                { key: "dateDebut", label: "Début de contrat" },
                { key: "action", label: "Action" }
            ],
            marge: null,
            title: '',
            selectedCompteur: [],
            listCompteurResult: [],
            messageDialogeError: "",
            messageDialogeSucces: "",
            dialogAddCompteur: false,
            ajouterUnAutreCompteur: false,
            dialog: false,
            error: false,
            loading: false,
            isLoading: false,
            messageDialoge: "",
            lesTarif: ['T1', 'T2', 'T3', 'T4'],
            lesProfils: ['P011', 'P012', 'P013', 'P014', 'P015', 'P016', 'P017', 'P018', 'P019'],
            data: {
                dateAjourdhui: new Date().getDate() + "/" + (parseInt(new Date().getMonth()) + 1) + "/" + new Date().getFullYear(),
                dateDebut: "10/10/2020",
                dateFin: "10/10/2022",
                pdl: "14552022144555",
                siret: "11111111111111",
                codeNaf: "1212Z",
                adresseSociete: "199 RUE, 12554 commune d'atte",
                adresseCompteur: "199 RUE, 12554 commune d'atte",
                codePostaleCompteur: '',
                voieCompteur: '',
                communeCompteur: '',
                codePostaleSociete: '',
                voieSociete: '',
                communeSociete: '',

                codePostale: '',
                nom: "chrif",
                prenom: "slimen",
                email: "slimenc6@gmail",
                numTele: "0755448899",
                consommationAnuuelle: "377.20",
                typeCompteur: "BTINFMUDT",
                kva: "24",
                raison: "Place des Energies",
                nbrMonth: '2',
                cabase: '',
                cahp: '',
                cahc: '',
            },
        }

    },
    computed: {
        rows() {
            return this.selectedCompteur.length
        }
    },
    methods: {
        prepareCompteurs() {
            // First, find the current compteur data

            const currentCpt = this.donnerCompteurs.find(cpt => cpt.NumCompteur === this.numCompteur);

            // Set the marge from current compteur
            if (currentCpt?.resultat?.donnerCompteur?.marge) {
                this.marge = currentCpt.resultat.donnerCompteur.marge;
            }

            // Filter and map the compteurs, excluding the current one
            this.listCompteurResult = this.donnerCompteurs
                .filter(cpt => {
                    // Keep compteur if it's in listCompteur AND it's not the current compteur
                    return this.listCompteur.includes(cpt.NumCompteur) &&
                        cpt.NumCompteur !== this.numCompteur;
                })
                .map(cpt => {
                    // Find the GEG DE BORDEAUX offer for this compteur
                    const gegOffer = cpt.resultat.result.find(offer =>
                        offer.fournisseur === "GEG"
                    );

                    // Return the mapped compteur data
                    return {
                        ...gegOffer,
                        numCompteur: cpt.NumCompteur,
                        enedis: cpt.resultat.dataEnedis.donneTechniqueInfo[0].result == "valide",
                        tarif: cpt.resultat.donnerCompteur?.tarif || "T1",
                        profil: cpt.resultat.donnerCompteur?.profilCompteur || "P011",
                        conso: Number(cpt.resultat.donnerCompteur?.CA) || 0,
                        dateDebut: gegOffer?.dateDebut?.split('/').reverse().join('-')
                    };
                });

        },
        toggle() {
            this.selectedCompteur = [...this.listCompteurResult]
        },
        closeModal() {
            this.dialogAddCompteur = false
            this.ajouterUnAutreCompteur = false
            this.selectedCompteur = []
        },
        openDialog() {
            this.dialogAddCompteur = true
            this.ajouterUnAutreCompteur = true
            this.prepareCompteurs(); // Ensure list is fresh when opening
        },
        supprimerCompteur(numComp) {
            const indexSelected = this.selectedCompteur.findIndex(x => x.numCompteur === numComp);
            if (indexSelected > -1) {
                this.selectedCompteur.splice(indexSelected, 1);
            }
        },
        async openAjouterUnAutreCompteur() {
            this.ajouterUnAutreCompteur = true
        },
        async createContrat() {

            try {
                this.dialog = true;
                this.loading = true;
                this.error = false;
                this.messageDialoge = "";
                var email = localStorage.getItem('email')
                var name = localStorage.getItem('vendeurName')
                var tel = localStorage.getItem('Telephone')
                let listCompteur = this.selectedCompteur.map(compteur => ({
                    ...compteur,
                    dateDebut: compteur.dateDebut.split('-').reverse().join('/')
                }))
                const dataToSend = {
                    courtier:'Green Eco Energy',
                    vendeur: { email, name, tel },
                    donner: {
                        ...this.donner,
                        optionTarifaire: this.optionTarifaire,
                        duree: this.donner.DUREE,
                        puissanceSouscrite: this.donner.kva,
                        enedis: this.donnerEnedis.donneTechniqueInfo[0].result == "valide"
                    },
                    listCompteur: listCompteur,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                };
                let urlCall;
                if (listCompteur.length > 0) {
                    urlCall = 'contracts/GegC4MultiSiteContractView';
                    console.log("here greater")
                } else {
                    urlCall = 'contracts/GegC4MonoSiteContractView';
                    console.log("here greater")

                }
                const response = await axios({
                    url: process.env.VUE_APP_URL_EDITION_CONTRACT_V2 + urlCall,
                    method: 'POST',
                    data: dataToSend,
                    responseType: 'blob',
                });

                // Create blob from response data directly
                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                });

                // Create download link
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `GEG${this.societe.Raison}_.docx`;

                // Trigger download
                document.body.appendChild(link);
                link.click();

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);

            } catch (err) {
                console.error(err);
            } finally {
                this.dialog = false
                this.error = false
                this.loading = false
                this.messageDialoge = ""
            }

        },
        async getCodePostale(adresse) {
            var myRe = new RegExp('\\d\\d\\d\\d\\d', '');
            var myArray = myRe.exec(adresse);
            var voie = ""
            var codePostale = ""
            var commune = ""
            if (myArray != null) {
                codePostale = myArray[0]
                if (adresse.split(codePostale).length >= 2) {
                    voie = adresse.split(codePostale)[0]
                    commune = adresse.split(codePostale)[1]
                } else {
                    commune = ""
                    voie = adresse
                }
            } else {
                voie = adresse
            }
            return [codePostale, voie, commune]
        },

        async downloadExcel() {
            try {
                this.dialog = true;
                this.loading = true;
                this.error = false;
                this.messageDialoge = "";
                const selectedDonner = this.selectedCompteur.map(compteur => ({
                    ...compteur,
                    dateDebut: compteur.dateDebut.split('-').reverse().join('/'),
                    numCompteur: compteur.numCompteur
                }))
                selectedDonner.push({
                    ...this.donner,
                    numCompteur: this.numCompteur,
                })
                const dataToSend = {
                    siret: this.societe.siret,
                    marge: this.marge,
                    token: this.getToken("token"),
                    donner: selectedDonner
                };

                const response = await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'generate-gaz-de-bordeaux-excel/',
                    method: 'POST',
                    data: dataToSend
                });

                const fileUrl = response.data.url;
                const fileName = fileUrl.split('/').pop();

                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', `GEG${this.societe.Raison}_${new Date().toISOString().split('T')[0]}.${fileName.split('.').pop()}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                console.error(err);
            } finally {
                this.dialog = false
                this.error = false
                this.loading = false
                this.messageDialoge = ""
            }
        },
    },

}
</script>
<style>
#tableau-edition-ekwateur.table-bordered thead th {
    background-color: #07a4ca;
    color: white;
    font-weight: bold;
}
</style>
